import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import { Autocomplete, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import { isNullOrEmpty } from '../helpers';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function ButtonSelectTerrritory(
  {
    selectedArea = undefined,
    areas = [],
    onChangeArea = (event, newInputValue, areas) => { },
    selectedDistrito = undefined, distritos = [],
    onChangeDistrito = (event, newInputValue, distritos) => { },
    selectedTerritorio = undefined,
    territorios = [],
    onChangeTerritorio = (event, newInputValue, territorios) => { },
    role = undefined,
    roleLevels = undefined,
    children,
    ...props
  }
) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  return (
    <React.Fragment >
      <Button onClick={toggleDrawer(true)} {...props}>
        {children}
      </Button>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: "clamp(100px,100vw,350px)" }}
          role="presentation"
        >
          <DrawerHeader style={{ backgroundColor: "#e6e8ea" }}>
            <Stack
              justifyContent="flex-start"
              alignItems="center"
              direction={'row'}
              spacing={2}
            >
              <IconButton sx={{ border: "1px solid" }} onClick={toggleDrawer(false)}>
                <ChevronLeftIcon />
              </IconButton>
              <h5>Selecione um filtro</h5>
            </Stack>
          </DrawerHeader>
          <Divider />
          {role?.accessLevel >= roleLevels?.viewAll &&
            <>
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Área Comercial:"
                    secondary={
                      <React.Fragment>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          // defaultValue={areas[0]}
                          value={selectedArea}
                          options={areas.filter(x => !isNullOrEmpty(x))}
                          noOptionsText="Área não encontrado"
                          disableClearable={selectedArea ? selectedArea === "TODOS" : true}
                          onChange={(event, newInputValue) => { onChangeArea(event, newInputValue, areas) }}
                          sx={{ maxWidth: 300 }}
                          renderInput={(params) => <TextField {...params} placeholder="Áreas" />}
                        />
                      </React.Fragment>
                    }
                    secondaryTypographyProps={{ component: "div" }}
                  />
                </ListItem>
              </List>
              <Divider />
            </>
          }
          {(role?.accessLevel >= roleLevels?.viewArea || (role?.accessLevel >= roleLevels?.viewManyDistrict && distritos.length > 2)) &&
            <>
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Distrito:"
                    secondary={
                      <React.Fragment>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          // defaultValue={distritos[0]}
                          value={selectedDistrito}
                          options={distritos.filter(x => !isNullOrEmpty(x.districtName))}
                          getOptionLabel={(option) => option.districtName}
                          noOptionsText="Distrito não encontrado"
                          disableClearable={selectedDistrito ? selectedDistrito?.districtName === "TODOS" : true}
                          onChange={(event, newInputValue) => { onChangeDistrito(event, newInputValue, distritos) }}
                          sx={{ maxWidth: 300 }}
                          renderInput={(params) => <TextField {...params} placeholder="Territórios" />}
                        />
                      </React.Fragment>
                    }
                    secondaryTypographyProps={{ component: "div" }}
                  />
                </ListItem>
              </List>
              <Divider />
            </>
          }
          {(role?.accessLevel >= roleLevels?.viewDistrict) &&
            <>
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Território:"
                    secondary={
                      <React.Fragment>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          // defaultValue={territorios[0]}
                          value={selectedTerritorio}
                          options={territorios.filter(x => !isNullOrEmpty(x.territoryName))}
                          getOptionLabel={(option) => option.territoryName}
                          renderOption={(props, option) => (
                            <Box component="li"  {...props} key={option.territoryId}>
                              {option.territoryName}
                            </Box>
                          )}
                          noOptionsText="Território não encontrado"
                          isOptionEqualToValue={(option, value) => option.territoryName === value.territoryName && value.territoryId === option.territoryId}
                          disableClearable={selectedTerritorio ? selectedTerritorio?.territoryName === "TODOS" : true}
                          onChange={(event, newInputValue) => { onChangeTerritorio(event, newInputValue, territorios) }}
                          sx={{ maxWidth: 300 }}
                          renderInput={(params) => <TextField {...params} placeholder="Territórios" />}
                        />
                      </React.Fragment>
                    }
                    secondaryTypographyProps={{ component: "div" }}
                  />
                </ListItem>
              </List>
            </>
          }
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
