import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { StocksActions } from "../../../actions/stock.actions";
import { whitespaceActions } from "../../../actions/whitespace.actions";
import { clientActions } from "../../../actions/client.actions";
import { userActions } from "../../../actions/user.actions";
import ChangeStock from "./ChangeStockMenu";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TableSortLabel from "@mui/material/TableSortLabel";
import { NumericFormat } from "react-number-format";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import OutboxIcon from "@mui/icons-material/Outbox";
import InventoryIcon from "@mui/icons-material/Inventory";
import EditIcon from '@mui/icons-material/Edit';
import FilterDialog from "./FilterDialog";
import ResumeDialog from "./ResumeTable"
import AddStockDialog from "./AddStockDialog";
import ButtonSelectTerrritory from "../../ButtonSelectTerrritory";
import InformationDrawer from "./InformationDrawer";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const headCells = [
  {
    id: "canal",
    align: "center",
    label: "Canal",
    editable: false,
    sortable: true,
    searchble: true,
    style: {
      position: "sticky",
      left: 0,
      minWidth: 130,
      maxWidth: 130,
      backgroundColor: "white",
    },
    zIndex: 101,
  },
  {
    id: "produto",
    align: "center",
    label: "Produto",
    editable: false,
    sortable: true,
    searchble: true,
    style: {
      position: "sticky",
      left: 130,
      minWidth: 130,
      maxWidth: 130,
      backgroundColor: "white",
      borderRight: "1px solid #747c7f !important",
    },
    zIndex: 100,
  },
  {
    id: "segmento",
    align: "center",
    label: "Segmento",
    editable: false,
    sortable: true,
    searchble: true,
    zIndex: 99,
    style: {
      whiteSpace: "nowrap",
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "raizCNPJ",
    align: "center",
    label: "Raiz de CNPJ",
    editable: false,
    sortable: true,
    searchble: true,
    zIndex: 98,
    style: {
      whiteSpace: "nowrap",
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "cnpj",
    align: "center",
    label: "CNPJ",
    format: "CNPJ",
    editable: false,
    sortable: true,
    searchble: true,
    hiddenInRaiz: true,
    zIndex: 98,
    style: {
      whiteSpace: "nowrap",
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "municipio",
    align: "center",
    label: "Município",
    editable: false,
    sortable: true,
    searchble: true,
    hiddenInRaiz: true,
    zIndex: 97,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "uf",
    align: "center",
    label: "UF",
    editable: false,
    sortable: true,
    searchble: true,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueFisicoAnterior",
    align: "center",
    label: "Estoque Físico Anterior",
    editable: false,
    sortable: true,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueDisponivelAnterior",
    align: "center",
    label: "Estoque Disponível Anterior",
    editable: false,
    sortable: true,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  // {
  //   id: "sellInCorteva",
  //   align: "center",
  //   label: "Sell In Corteva",
  //   //secondaryLabel: "(Kg | L | sc)",
  //   editable: false,
  //   sortable: true,
  //   style: {
  //     minWidth: "fit-content",
  //     maxWidth: "160px",
  //   }
  // },
  // {
  //   id: "sellInIntegra",
  //   align: "center",
  //   label: "Sell In Integra",
  //   //secondaryLabel: "(Kg | L | sc)",
  //   editable: false,
  //   sortable: true,
  //   style: {
  //     minWidth: "fit-content",
  //     maxWidth: "160px",
  //   }
  // },
  // {
  //   id: "sellOutIntegra",
  //   align: "center",
  //   label: "Sell Out Integra",
  //   //secondaryLabel: "(Kg | L | sc)",
  //   editable: false,
  //   sortable: true,
  //   style: {
  //     minWidth: "fit-content",
  //     maxWidth: "160px",
  //   }
  // },
  // {
  //   id: "saldoEstoque",
  //   align: "center",
  //   label: "Saldo Estoque",
  //   //secondaryLabel: "(Kg | L | sc)",
  //   editable: false,
  //   sortable: true,
  //   style: {
  //     minWidth: "fit-content",
  //     maxWidth: "160px",
  //   }
  // },
  {
    id: "estoqueFisicoIntegra",
    align: "center",
    label: "Estoque Físico Integra",
    //secondaryLabel: "(Kg | L | sc)",
    editable: false,
    sortable: true,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueDisponivelIntegra",
    align: "center",
    label: "Estoque Disponivel Integra",
    //secondaryLabel: "(Kg | L | sc)",
    editable: false,
    sortable: true,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueFisico",
    align: "center",
    label: "Estoque Físico",
    //secondaryLabel: "(Kg | L | sc)",
    editable: true,
    sortable: false,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueDisponivel",
    align: "center",
    label: "Estoque Disponível",
    //secondaryLabel: "(Kg | L | sc)",
    editable: true,
    sortable: false,
    style: {
      minWidth: "fit-content",
      maxWidth: "160px",
    }
  },
  {
    id: "estoqueValido",
    align: "center",
    label: "",
    action: true,
    editable: false,
    sortable: false,
    style: {
      position: "sticky",
      left: 0,
      minWidth: 75,
      maxWidth: 75,
    },
    zIndex: 100,
  },
];

function formatToCNPJ(srt) {
  return srt?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5") ?? "";
}

function formatNumber(num) {
  return num?.toLocaleString('pt-BR', { maximumFractionDigits: 0 }) ?? "";
}

function removeAccents(str) {
  return str?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")?.replace(/\s+/g, "")?.toLowerCase() ?? "";
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = React.memo(function EnhancedTableHead(props) {
  const {
    period,
    role,
    order,
    orderBy,
    handleSearch,
    onRequestSort,
    actions,
    rows,
    unfilteredRows,
  } = props;

  // Memoize sort handler so that it does not recreate on every render
  const createSortHandler = useCallback(
    (property) => (event) => {
      onRequestSort(event, property);
    },
    [onRequestSort]
  );

  return (
    <TableHead
      sx={{
        th: {
          color: "#FFF",
          fontWeight: "bold",
          backgroundColor: "var(--cort-blue) !important",
        },
      }}
    >
      <TableRow>
        {headCells
          .filter((x) => (period?.porRaiz ? !x.hiddenInRaiz : true))
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              sortDirection={orderBy === headCell.id ? order : false}
              size="medium"
              sx={{
                "*": {
                  color: "#FFF !important",
                  "&:hover": {
                    color: "#FFF",
                  },
                },
                minWidth: "160px",
                zIndex: headCell.zIndex,
                ...headCell.style,
              }}
            >
              {headCell.action ? (
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                  <ChangeStock
                    sort={createSortHandler(headCell.id)}
                    actions={actions}
                    role={role}
                    rowsIds={rows.map((x) => x.id)}
                  />
                  {orderBy === headCell.id &&
                    (order === "asc" ? (
                      <ArrowUpwardIcon sx={{ fontSize: "1rem" }} />
                    ) : (
                      <ArrowDownwardIcon sx={{ fontSize: "1rem" }} />
                    ))}
                </Stack>
              ) : headCell.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  hideSortIcon={orderBy !== headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ margin: "0px !important" }}>{headCell.label}</div>
                    <div style={{ margin: "0px !important" }}>{headCell.secondaryLabel}</div>
                  </div>
                  {headCell.searchble && (
                    <FilterDialog
                      applyFilter={handleSearch}
                      rows={rows}
                      unfilteredRows={unfilteredRows}
                      label={headCell.label}
                    />
                  )}
                </TableSortLabel>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ margin: "0px !important" }}>{headCell.label}</div>
                  <div style={{ margin: "0px !important" }}>{headCell.secondaryLabel}</div>
                  {headCell.searchble && (
                    <FilterDialog
                      applyFilter={handleSearch}
                      rows={rows}
                      unfilteredRows={unfilteredRows}
                      label={headCell.label}
                    />
                  )}
                </div>
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
});

const EnhancedTableToolbar = React.memo(function EnhancedTableToolbar({
  rows,
  channels,
  products,
  municipios,
  msgSave,
  actions,
  stockPeriod,
  role,
}) {
  const actualPeriod = useMemo(() => stockPeriod.find((x) => x.ativo), [stockPeriod]);
  const isAdmin = useMemo(() => role === "Admin", [role]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 30;

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handlePeriodChange = useCallback(
    (_, period) => {
      if (period.id) {
        actions.setStockPeriod(period.id);
        setAnchorEl(null);
      }
    },
    [actions]
  );

  return (
    <Grid
      container
      columnSpacing={2}
      sx={{
        margin: "0px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Grid item xs={4.5} sx={{ paddingLeft: "0px !important" }}>
        <Stack direction={"column"}>
          <Stack direction={"row"}>
            <h4
              style={{
                color: "var(--cort-blue)",
                fontWeight: "bold",
                alignContent: "center",
                margin: "0",
              }}
            >
              Gerenciar Estoque -{" "}
              {(actualPeriod?.ano && `${actualPeriod?.ano} - Mês ${actualPeriod?.trimestre}`) || ""}
            </h4>
            {isAdmin && (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: "40px",
                  height: "38px",
                  marginLeft: "10px",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Alterar Período" placement="top">
                  <IconButton
                    sx={{
                      color: "var(--cort-blue)",
                      "&:hover": { color: "#fff", backgroundColor: "var(--cort-blue)" },
                      borderRadius: "6px",
                      marginLeft: "10px",
                    }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <SwapHorizIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                    },
                  }}
                >
                  {stockPeriod.map((option) => (
                    <MenuItem key={option.id} onClick={(event) => handlePeriodChange(event, option)}>
                      {option.trimestre} - {option.ano}
                    </MenuItem>
                  ))}
                </Menu>
              </Stack>
            )}
          </Stack>
          <h6 style={{ color: "#000", fontStyle: "italic", fontWeight: "300" }}>
            Unidades de medida ( Kg | L | sc )
          </h6>
        </Stack>
      </Grid>
      <Grid item xs={4.5} sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ flex: "1 1 40%", textAlign: "end" }} component="div">
          {msgSave}
        </Typography>
        <Tooltip title="Atualizar dados">
          <IconButton onClick={() => actions.requestAllData()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <InformationDrawer />
      </Grid>
      <Grid item xs={0.5}>
        <AddStockDialog
          period={actualPeriod}
          channels={channels}
          products={products}
          confirmAction={actions.handleCreateStock}
        />
      </Grid>
      <Grid item xs={0.5}>
        <ResumeDialog rows={rows} />
      </Grid>
    </Grid>
  );
});

const ActionButton = React.memo(function ActionButton({ role, row, handleInterectStock }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <React.Fragment>
      {row.loading ? (
        <CircularProgress sx={{ height: "24px !important", width: "24px !important" }} />
      ) : (
        <div>
          <Tooltip
            title={
              row.status === 0
                ? "Não Validado"
                : row.status === 1
                  ? "Aguardando Aprovação"
                  : "Aprovado"
            }
          >
            <IconButton
              aria-label={
                row.status === 0
                  ? "Não Validado"
                  : row.status === 1
                    ? "Aguardando Aprovação"
                    : "Aprovado"
              }
              color={row.status === 0 ? "vermelho" : row.status === 1 ? "azul" : "verde"}
              onClick={(e) => {
                if (role?.includes("Sales Representative")) {
                  if (row.status === 0) {
                    handleInterectStock(row.id, 1);
                  } else if (row.status === 1) {
                    handleInterectStock(row.id, 0);
                  }
                } else if (role?.includes("DSM")) {
                  if (row.status !== 2) {
                    handleInterectStock(row.id, 2);
                  } else {
                    handleInterectStock(row.id, 1);
                  }
                } else if (role?.includes("Admin") || role?.includes("KAM")) {
                  if (row.status !== 2) {
                    handleInterectStock(row.id, 2);
                  } else {
                    handleInterectStock(row.id, 0);
                  }
                } else {
                  handleClick(e);
                }
              }}
            >
              {row.status > 0 ? <InventoryIcon /> : <OutboxIcon />}
            </IconButton>
          </Tooltip>
          {role?.includes("Admin") && (
            <Menu
              id="stock-validate-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "stock-validate-btn",
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => { handleClose(); handleInterectStock(row.id, 0); }}>
                Apontar Novamente
              </MenuItem>
              {row.status === 0 && (
                <MenuItem onClick={() => { handleClose(); handleInterectStock(row.id, 1); }}>
                  Validar
                </MenuItem>
              )}
              {row.status === 1 && (
                <MenuItem onClick={() => { handleClose(); handleInterectStock(row.id, 2); }}>
                  Aprovar
                </MenuItem>
              )}
            </Menu>
          )}
        </div>
      )}
    </React.Fragment>
  );
});

function StockTable(props) {
  const { role, rows, actions, stockProps, municipios, msgSave, handleSearch, stockPeriod } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("nome");

  const handleApplyFilter = useCallback(
    (value, column) => {
      handleSearch(value, column);
      setPage(0);
    },
    [handleSearch]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }, []);

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  // Memoize sorted rows so sorting is recalculated only when rows, order, or orderBy change.
  const sortedRows = useMemo(() => (rows ? stableSort(rows, getComparator(order, orderBy)) : []), [
    rows,
    order,
    orderBy,
  ]);

  // And memoize paginated rows so the slice is recalculated only when needed.
  const paginatedRows = useMemo(
    () =>
      rowsPerPage > 0
        ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : sortedRows,
    [sortedRows, page, rowsPerPage]
  );

  return (
    <div>
      <EnhancedTableToolbar
        rows={rows}
        channels={stockProps.channels}
        products={stockProps.products}
        municipios={municipios}
        msgSave={msgSave ? msgSave : "Sem Alterações"}
        actions={actions}
        stockPeriod={stockPeriod}
        role={role}
      />
      <TableContainer
        component={Paper}
        sx={{
          minWidth: 650,
          maxHeight: "calc(56vh - 16px)",
          th: { fontSize: "0.7rem", padding: "4px 8px" },
          td: {
            minWidth: "fit-content",
            maxWidth: "160px",
            fontSize: "0.7rem",
            padding: "4px",
          },
          "tr:nth-of-type(odd) td": { backgroundColor: "#f5f5f5" },
        }}
      >
        <Table stickyHeader size="small">
          <EnhancedTableHead
            period={stockPeriod.find((x) => x.ativo)}
            role={role}
            rows={rows}
            unfilteredRows={stockProps.stocks}
            actions={actions}
            order={order}
            orderBy={orderBy}
            handleSearch={handleApplyFilter}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {paginatedRows.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow" + row.id}>
                {headCells
                  .filter((x) => (stockPeriod.find((x) => x.ativo)?.porRaiz ? !x.hiddenInRaiz : true))
                  .map((column) => {
                    const value = row[column.id];
                    const isInput = column.editable;
                    const isAction = column.action;
                    const isBoolean = value === true || value === false;

                    return (
                      <TableCell
                        key={`${column.id}${row.id}`}
                        align={column.align}
                        sx={{
                          ...column.style,
                          borderBottom: "1px solid #747c7f !important",
                        }}
                      >
                        {isBoolean && (value ? "Sim" : "Não")}
                        {isAction && (
                          <ActionButton role={role} row={row} handleInterectStock={actions.handleConfirmStock} />
                        )}
                        {isInput && (
                          <NumericFormat
                            disabled={
                              role?.includes("DSM") ||
                                role?.includes("KAM") ||
                                role?.includes("Admin")
                                ? row.status === 2
                                : row.status >= 1
                            }
                            customInput={TextField}
                            name={column.id}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={1}
                            size="small"
                            value={
                              (column.id === "estoqueFisico" && row.apontadoManualmenteFisico) ||
                                (column.id === "estoqueDisponivel" && row.apontadoManualmenteDisponivel) ||
                                row.status >= 1 ||
                                value > 0
                                ? value
                                : ""
                            }
                            onValueChange={(e) =>
                              actions.handleChangeNumeric(e.value, column.id, row.id)
                            }
                            onBlur={(e) => {
                              actions.handleBlurNumeric(e, row);
                            }}
                            sx={{
                              minWidth: "90px",
                              "div input": { padding: "8px !important" },
                            }}
                            className="numericInput"
                          />
                        )}
                        {!isInput &&
                          !isAction &&
                          (column.format === "CNPJ" ? formatToCNPJ(value) : formatNumber(value))}
                      </TableCell>
                    );
                  })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : to}`
        }
        backIconButtonText="Página anterior"
        nextIconButtonText="Próxima página"
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rows?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          borderRadius: "0 0 8px 8px",
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        }}
      />
    </div>
  );
}

class StockPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchRaizCnpj: [],
      searchCnpj: [],
      searchChannel: [],
      searchMunicipio: [],
      searchProduct: [],
      searchUf: [],
      searchSegmento: [],

      territorio: undefined,
      area: undefined,
      distrito: undefined,
      isSetStorage: false,
      stockRows: undefined
    };

    this.onChangeTerritorio = this.onChangeTerritorio.bind(this);
    this.setByStorage = this.setByStorage.bind(this);
    this.onChangeArea = this.onChangeArea.bind(this);
    this.onChangeDistrito = this.onChangeDistrito.bind(this);
    this.onChangeTerritorio = this.onChangeTerritorio.bind(this);
  }

  componentWillMount() {
    //Filter Territory Requests
    this.props.actions.requestTerritorios();
    this.props.actions.requestRole();
    this.props.actions.requestRoleLevels();

    //Stocks Table Requests
    this.props.actions.requestUser();
    this.props.actions.requestAllData();
    this.props.actions.requestTerritoryIbge();
    this.props.actions.requestStockPeriods();
    this.props.actions.getAllMunicipios();
  }

  // Mapping for search keys
  handleSearch = (value, column) => {
    const keyMap = {
      raizdecnpj: "searchRaizCnpj",
      cnpj: "searchCnpj",
      canal: "searchChannel",
      municipio: "searchMunicipio",
      produto: "searchProduct",
      uf: "searchUf",
      segmento: "searchSegmento"
    };

    // Normalize column name and get the corresponding state key
    const normalizedColumn = removeAccents(column).toLowerCase();
    const stateKey = keyMap[normalizedColumn];

    if (stateKey) {
      this.setState({ [stateKey]: value });
    }
  };

  // Helper function to handle selection changes
  handleSelectionChange = (newInputValue, options, { storageKey, stateKey, resets = [], callback } = {}) => {
    const valueToSet = newInputValue !== null ? newInputValue : options[0];

    const newState = { [stateKey]: valueToSet };
    resets.forEach(resetKey => (newState[resetKey] = undefined));

    this.setState(newState);
    localStorage.setItem(storageKey, JSON.stringify(valueToSet));
    resets.forEach(resetKey => localStorage.removeItem(resetKey));

    if (callback && typeof callback === "function") {
      callback(valueToSet);
    }
  };

  onChangeArea = (_, newInputValue, options) => {
    this.handleSelectionChange(newInputValue, options, {
      storageKey: "Area",
      stateKey: "area",
      resets: ["Distrito", "Territorio"]
    });
  };

  onChangeDistrito = (_, newInputValue, options) => {
    this.handleSelectionChange(newInputValue, options, {
      storageKey: "Distrito",
      stateKey: "distrito",
      resets: ["Territorio"]
    });
  };

  onChangeTerritorio = (_, newInputValue, options) => {
    this.handleSelectionChange(newInputValue, options, {
      storageKey: "Territorio",
      stateKey: "territorio",
      // Callback to update territoryId in the parent (or redux) action
      callback: (value) => this.props.actions.changeTerritorio(value.territoryId)
    });
  };

  // Optimized setByStorage using array helpers
  setByStorage = (areas = [], distritos = [], territorios = []) => {
    const storedArea = JSON.parse(localStorage.getItem("Area"));
    const storedDistrito = JSON.parse(localStorage.getItem("Distrito"));
    const storedTerritorio = JSON.parse(localStorage.getItem("Territorio"));

    if (storedArea && areas.includes(storedArea)) {
      this.setState({ area: storedArea });
    } else {
      localStorage.removeItem("Area");
    }

    if (storedDistrito && distritos.some(x => x.districtName === storedDistrito.districtName)) {
      this.setState({ distrito: storedDistrito });
    } else {
      localStorage.removeItem("Distrito");
    }

    if (storedTerritorio && territorios.some(x => x.territoryName === storedTerritorio.territoryName)) {
      this.setState({ territorio: storedTerritorio });
    } else {
      localStorage.removeItem("Territorio");
    }

    this.setState({ isSetStorage: true });
  };

  render() {
    // Helper function to format a string (lowercase and remove accents)
    const formatString = (str) => removeAccents(str.toLowerCase());

    // Generic filter function for a field based on a search array.
    // Pass a function to extract the value from a row.
    // The option `exact: true` performs an equality check rather than includes().
    const filterRows = (rows, searchArray, fieldAccessor, { exact = false } = {}) => {
      if (!searchArray || searchArray.length === 0) return rows;
      return rows.filter(item => {
        const value = formatString(fieldAccessor(item));
        return searchArray.some(term => {
          const termFormatted = formatString(term);
          return exact ? value === termFormatted : value.includes(termFormatted);
        });
      });
    };

    //
    // 1. Build the initial rows from stocks data
    //
    let rows = [];
    let territorio = "";
    let role = "";
    let roleLevels = null;
    let territorios = [{ territoryName: "TODOS" }];
    let areas = ["TODOS"];
    let distritos = [{ districtName: "TODOS" }];

    const stocks = this.props.stocks.stocks;
    if (stocks && stocks.length > 0) {
      rows = stocks.map(x => ({
        id: x.id,
        status: x.estoqueValido,
        loading: x?.loading ?? false,
        codIbge: x.estoqueCanal.codIbge,
        cnpj: x.estoqueCanal.cnpj,
        produto: x.estoqueProduto.nome,
        produtoLinha: x.estoqueProduto.linha,
        raizCNPJ: x.estoqueCanal.raizCNPJ,
        canal: x.estoqueCanal.canal,
        canalTipo: x.estoqueCanal.tipo,
        municipio: x.estoqueCanal.municipio,
        uf: x.estoqueCanal.uf,
        segmento: x.estoqueProduto.segmento,
        apontadoManualmente: x.apontadoManualmente,
        apontadoManualmenteFisico: x.apontadoManualmenteFisico,
        apontadoManualmenteDisponivel: x.apontadoManualmenteDisponivel,
        estoqueValido: x.estoqueValido,
        validacaoAnterior: x.validacaoAnterior,
        estoqueFisicoAnterior: x.estoqueFisicoAnterior,
        estoqueDisponivelAnterior: x.estoqueDisponivelAnterior,
        sellInCorteva: x.sellInCorteva,
        sellInIntegra: x.sellInIntegra,
        sellOutIntegra: x.sellOutIntegra,
        saldoEstoque: x.saldoEstoque,
        estoqueFisicoIntegra: x.estoqueFisicoIntegra,
        estoqueDisponivelIntegra: x.estoqueDisponivelIntegra,
        estoqueFisico: x.estoqueFisico,
        oldestoqueFisico: x.oldestoqueFisico,
        estoqueDisponivel: x.estoqueDisponivel,
        oldestoqueDisponivel: x.oldestoqueDisponivel,
        dataCriacao: x.estoqueCanal.dataCriacao,
      }));
    }

    //
    // 2. Apply search filters (using the generic helper)
    //
    rows = filterRows(rows, this.state.searchRaizCnpj, (k) => k.cnpj);
    rows = filterRows(rows, this.state.searchCnpj, (k) => k.cnpj);
    rows = filterRows(rows, this.state.searchChannel, (k) => k.canal, { exact: true });
    rows = filterRows(rows, this.state.searchMunicipio, (k) => k.municipio);
    rows = filterRows(rows, this.state.searchProduct, (k) => k.produto);
    rows = filterRows(rows, this.state.searchUf, (k) => k.uf);
    rows = filterRows(rows, this.state.searchSegmento, (k) => k.segmento);

    //
    // 3. Determine territory based on user role/roleLevels
    //
    if (
      !this.props.user.isLoadingRole &&
      this.props.user.role &&
      !this.props.user.isLoadingRoleLevels &&
      this.props.user.roleLevels
    ) {
      roleLevels = this.props.user.roleLevels;
      role = this.props.user.role.role;

      if (role.accessLevel === roleLevels.viewAll) {
        territorio = "";
      } else if (role.accessLevel >= roleLevels.viewArea) {
        territorio = this.props.user.role.areaName;
      } else if (role.accessLevel >= roleLevels.viewManyDistrict) {
        territorio = this.props.user.role.districtName;
      } else if (role.accessLevel >= roleLevels.viewDistrict) {
        territorio = this.props.user.role.districtName;
      } else if (this.props.user.role.territory) {
        territorio = this.props.user.role.territory.territoryName;
      }
    }

    //
    // 4. Apply territory/district filtering (if the user has territories)
    //
    if (
      !this.props.user.isLoadingTerritorios &&
      this.props.user.territorios.length > 0 &&
      !this.props.user.isLoadingRole &&
      this.props.user.role &&
      !this.props.user.isLoadingRoleLevels &&
      this.props.user.roleLevels
    ) {
      const territoriosIbge = this.props.stocks.territories;
      let territoriosToFilter = this.props.user.territorios;
      // Merge areas from state and role, then filter out empty strings
      areas = Array.from(new Set(areas.concat(this.props.user.role.areas).filter(Boolean)));
      let distritosToFilter = this.props.user.role.distritos;

      if (!this.state.isSetStorage) {
        this.setByStorage(this.props.user.role.areas, this.props.user.role.distritos, this.props.user.territorios);
      }

      // Filter by area if defined (for viewAll role)
      if (this.state.area && role?.accessLevel === roleLevels?.viewAll && this.state.area !== "TODOS") {
        distritosToFilter = distritosToFilter.filter(x => x.areaName === this.state.area);
        territoriosToFilter = territoriosToFilter.filter(x => x.areaName === this.state.area);
        rows = rows.filter(x => territoriosToFilter.some(t => x.territory === t.territoryName));
      }

      // Merge and clean up districts
      distritos = Array.from(new Set(distritos.concat(distritosToFilter).filter(x => x.districtName)));

      // Filter by distrito if set and the role permits
      if (
        territoriosIbge.length &&
        this.state.distrito &&
        (role?.accessLevel >= roleLevels?.viewArea ||
          (role?.accessLevel >= roleLevels?.viewManyDistrict && distritos.length > 2))
      ) {
        if (this.state.distrito.districtName !== "TODOS") {
          territoriosToFilter = territoriosToFilter.filter(x => x.districtName === this.state.distrito.districtName);
          const productsFilter = [...new Set(territoriosToFilter.flatMap(x => x.businessLine.split(";")))];
          const uniqueTypesFilter = [...new Set(territoriosToFilter.map(x => x.territoryType))];
          const filteredTerritoriesIBGE = territoriosIbge
            .filter(y => territoriosToFilter.some(x => x.territoryId === y.territoryId))
            .map(z => z.codIbge);
          rows = rows.filter(
            x =>
              filteredTerritoriesIBGE.includes(x.codIbge) &&
              productsFilter.includes(x.produtoLinha) &&
              uniqueTypesFilter.includes(x.canalTipo)
          );
        }
      }

      // Filter by território if set and the role permits
      if (territoriosIbge.length && this.state.territorio && role?.accessLevel >= roleLevels?.viewDistrict) {
        if (this.state.territorio.territoryName !== "TODOS") {
          const productsFilter = [...new Set(this.state.territorio.businessLine.split(";"))];
          const uniqueTypesFilter = [...new Set(territoriosToFilter.map(x => x.territoryType))];
          const filteredTerritoriesIBGE = territoriosIbge
            .filter(y => this.state.territorio.territoryId === y.territoryId)
            .map(z => z.codIbge);
          rows = rows.filter(
            x =>
              filteredTerritoriesIBGE.includes(x.codIbge) &&
              productsFilter.includes(x.produtoLinha) &&
              uniqueTypesFilter.includes(x.canalTipo)
          );
        }
      }

      territorios = territorios.concat(territoriosToFilter);
    }

    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.stocks.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div
          className="card-header"
          style={{ padding: "var(--app-card-header-padding)" }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important",
              },
            }}
          >
            <Link to={"/"}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Typography color="text.primary">Estoque</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "16px" }}>
          <Stack direction={"column"}>

            {/* {(!(this.props.user.isLoadingTerritorios || this.props.user.isLoadingRole || this.props.user.isLoadingRoleLevels) && role.accessLevel > roleLevels?.viewTerritory) &&
              <Stack
                justifyContent="flex-start"
                alignItems="center"
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
                sx={{
                  backgroundColor: "#e6e8ea",
                  fontWeight: "500",
                  padding: "8px 16px !important",
                  borderRadius: "4px",
                  marginBottom: "16px"
                }}
              >
                <h6 className="mb-0">Selecione um território para filtrar a visualização dos dados:</h6>
                <ButtonSelectTerrritory variant="outlined" color={"azul"} startIcon={<EditIcon />}
                  selectedArea={this.state.area ? this.state.area : areas[0]}
                  areas={areas}
                  onChangeArea={this.onChangeArea}
                  selectedDistrito={this.state.distrito ? this.state.distrito : distritos[0]}
                  distritos={distritos}
                  onChangeDistrito={this.onChangeDistrito}
                  selectedTerritorio={this.state.territorio ? this.state.territorio : territorios[0]}
                  territorios={territorios}
                  onChangeTerritorio={this.onChangeTerritorio}
                  role={role}
                  roleLevels={roleLevels}
                >Editar Filtros</ButtonSelectTerrritory>
                {(role.accessLevel === roleLevels.viewAll) &&
                  <p className="mb-0">Area Comercial: {this.state.area ? this.state.area : areas[0]}</p>
                }
                {(role?.accessLevel >= roleLevels?.viewArea || (role?.accessLevel >= roleLevels?.viewManyDistrict && distritos.length > 2)) &&
                  <p className="mb-0">Distrito: {(this.state.distrito ? this.state.distrito : distritos[0]).districtName}</p>
                }
                {(role?.accessLevel >= roleLevels?.viewDistrict) &&
                  <p className="mb-0">Território: {(this.state.territorio ? this.state.territorio : territorios[0]).territoryName}</p>
                }
              </Stack>
            } */}

            <StockTable
              role={role?.name}
              rows={rows}
              actions={this.props.actions}
              stockProps={this.props.stocks}
              municipios={this.props.whitespace.municipios}
              msgSave={this.props.stocks.messages}
              handleSearch={this.handleSearch}
              stockPeriod={this.props.stocks.periods}
            />
          </Stack>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  whitespace: state.whitespace,
  stocks: state.stocks,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...StocksActions,
      ...whitespaceActions,
      ...clientActions,
      ...userActions
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockPage);
